export default {
  id: {
    type: Number,
    visibility: false
  },
  name: {
    label: 'Nombre',
    type: String
  },
  uniqueId: {
    label: 'Identificador',
    type: String
  },
  groupId: {
    label: 'Grupo',
    type: Number,
    optional: true,
    model: 'groups',
    module: 'devices',
    autocomplete: false
  },
  phone: {
    label: 'Teléfono',
    type: String,
    optional: true
  },
  model: {
    label: 'Modelo',
    type: String,
    optional: true
  },
  contact: {
    label: 'Contacto',
    type: String,
    optional: true
  },
  user: {
    label: 'Usuario (RFC)',
    type: String,
    optional: true
  },
  category: {
    label: 'Categoría',
    type: String,
    optional: true,
    options: [
      {
        id: 'ambulancia',
        name: 'Ambulancia',
      }, {
        id: 'ayudantia',
        name: 'Ayudantía',
      }, {
        id: 'bombero',
        name: 'Bombero (Persona)',
      }, {
        id: 'dif',
        name: 'DIF (Vehiculo)',
      }, {
        id: 'estatalp',
        name: 'Policía Estatal (Persona)',
      }, {
        id: 'ministerial',
        name: 'Policía de Investigación (Vehiculo)',
      }, {
        id: 'ministerialp',
        name: 'Policía de Investigación (Persona)',
      }, {
        id: 'militar',
        name: 'Policía Militar (Persona)',
      }, {
        id: 'municipal',
        name: 'Policía Municipal (Vehiculo)',
      }, {
        id: 'municipalp',
        name: 'Policía Municipal (Persona)',
      }, {
        id: 'recorridos',
        name: 'Recorridos (Vehiculo)',
      }, {
        id: 'servicios_establecidos',
        name: 'Servicios Establecidos (Vehiculo)',
      }, {
        id: 'medidas_cautelares',
        name: 'Medidas Cautelares (Vehiculo)',
      }, {
        id: 'stj',
        name: 'Poder Judicial (Vehiculo)',
      }, {
        id: 'pcivil',
        name: 'Protección Civil (Vehiculo)',
      }, {
        id: 'elementoPcivil',
        name: 'Protección Civil (Persona)',
      }, {
        id: 'bronce',
        name: 'Unidad Especial (Vehiculo)',
      }, {
        id: 'car',
        name: 'Vehiculo',
      }, {
        id: 'arrow',
        name: 'Flecha',
      }]
  },
  fuelType: {
    label: 'Tipo de combustible',
    type: String,
    options: [{
      id: 'magna',
      name: 'Magna'
    }, {
      id: 'premium',
      name: 'Premium'
    }, {
      id: 'diesel',
      name: 'Diesel'
    }, {
      id: 'gas',
      name: 'Gas'
    }],
    optional: true
  },
  kmPerLiter: {
    label: 'Kilometros por litro',
    type: Number,
    optional: true
  },
  conductor: {
    label: 'Conductor',
    type: String,
    optional: true
  },
  disabled: {
    label: 'Deshabilitado',
    type: Boolean,
    optional: true
  },
  instantMessenger: {
    label: 'Mensajería instantanea',
    type: String,
    optional: true,
    options: [{
      id: 'None',
      name: 'Ninguna'
    }, {
      id: 'Whatsapp',
      name: 'Whatsapp'
    }, {
      id: 'Telegram',
      name: 'Telegram'
    }]
  },
  dvrSeparator: {
    label: 'Camaras',
    type: String,
    optional: true,
    separator: true
  },
  isDvr: {
    label: 'Tiene Camaras?',
    type: Boolean,
    onChange: (val, fields) => {
      fields.dvrId.setVisibility(val)
      fields.hu.setVisibility(val)
      fields.channel01.setVisibility(val)
      fields.channel02.setVisibility(val)
      fields.channel03.setVisibility(val)
      fields.channel04.setVisibility(val)
    }
  },
  dvrId: {
    label: 'DRV ID',
    type: String,
  },
  hu: {
    label: 'HU',
    type: String,
  },
  channel01: {
    label: 'Channel 01',
    type: Boolean
  },
  channel02: {
    label: 'Channel 02',
    type: Boolean
  },
  channel03: {
    label: 'Channel 03',
    type: Boolean
  },
  channel04: {
    label: 'Channel 04',
    type: Boolean
  },
  phoneList: {
    label: 'Listado de teléfonos',
    type: Array,
    optional: true,
  },
  'phoneList.$': {
    type: String,
    options: true
  },
  attributes: {
    label: 'Atributos',
    type: Object,
    optional: true,
    attributes: true
  }
};
